$blue : #C8EAFD;
$darkBlue : #69B7E3;
$realyDarkBlue: #034D78;
$green : #B1D8B7;
$darkGreen : #2F5233;
$red : #FADCD9;
$darkRed : #F8AFA6;
$realyDarkRed: #E63946;
$grey : #ECECEC;
$darkGrey : #949494;
$black : #000000;
$overlay: rgba(48, 48, 48, 0.8);
$white : #ffffff;

:export {
  blue : $blue;
  darkBlue : $darkBlue;
  reallyDarkBlue: $realyDarkBlue;
  green : $green;
  darkGreen : $darkGreen;
  red: $red;
  darkRed : $darkRed;
  $realyDarkBlue: $realyDarkRed;
  grey : $grey;
  darkGrey : $darkGrey;
  black : $black;
  overlay : $overlay;
  white : $white;
}