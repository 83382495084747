@import "~bootstrap/scss/bootstrap";
@import "../../assets/styles/regular";
@import "../../assets/styles/colors";

.row {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.col-md-8 {
  padding-left: 0;
  padding-right: 0;
}

.col-md-4 {
  padding-right: 0;
  padding-left: 0;
}

.col-md-10 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.col-md-2,
.col-md-1,
.col-md-8 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.front-screen{
  padding: 20px;
  height: 100vh;
  width: 100%;
  background-color: $grey;
}

.banner{
  padding-right: 20px !important;
  &-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  
}

.cart{
  padding: 16px 20px;
  position: relative;
  height: calc(100vh - 40px);
  background-color: $white;
  display: flex;
  gap: 10px;
  flex-direction: column;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.customer {
    background-color: $grey;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    &_data {
      outline: none;
      border: none;
      font-family: "Inter-Bold";
      background-color: transparent;
      color: $black;
      font-size: 1.2em;
      width: 100%;
  
      &::placeholder {
        color: $black;
      }
    }
  
    &_buttons {
      display: flex;
      gap: 10px;
      align-items: center;
  
    }
  
    &_edit {
      background-color: $green;
      padding: 8px 12px;
      color: $darkGreen;
      border: none;
      outline: none;
      border: none;
      opacity: .9;
  
      &:hover {
        opacity: 0.7;
      }
  
      &:focus {
        opacity: 1;
      }
  
      &.table_numb {
        background-color: $darkBlue;
        color: $realyDarkBlue;
      }
    }
}

.transact {
  &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 16px 0;
      border-bottom: 2px dashed $grey;
      margin-bottom: 16px;
  }

  &_code {
      font-family: "Inter-Bold";
      color: $black;
  }

  &_deleteAll {
      background-color: $red;
      color: $black;
      border: none;
      outline: none;
      padding: 8px 15px;
      outline: none;
      border: none;
      opacity: .9;

      &:hover {
      opacity: 0.7;
      }

      &:focus {
      opacity: 1;
      }
  }

  &_list {
    overflow-y: auto;
    padding-right: 10px;
    flex: 1 1 0;
    order: 0;

    &__item {
    &:not(:last-child) {
        margin-bottom: 10px;
    }

    .col-md-1,
    .col-md-6,
    .col-md-4 {
        padding-left: 0;
        padding-right: 0;
    }

    &_qty {
        text-align: left;
        font-family: "Inter-Medium";
    }

    &_title {
        text-align: left;
        font-family: "Inter-Medium";
    }

    &_price {
        text-align: left;
        font-family: "Inter-Medium";
        font-size: 0.8em;
        color: $darkGrey;
    }

    &_total {
        text-align: right;
        font-family: "Inter-Medium";

        &.discounted {
        text-decoration: line-through;
        }
    }

    &_modifier {
        &_title {
        text-align: left;
        font-family: "Inter-Medium";
        color: $darkGrey;
        font-size: 0.9em;
        }

        &_price {
        text-align: right;
        font-family: "Inter-Medium";
        color: $darkGrey;
        font-size: 0.9em;
        }

    }

    &_delete {
        display: flex;
        justify-content: center;
        align-items: center;

        .delete-btn {
        border: none;
        outline: none;
        background-color: $red;
        color: $white;
        padding: 10px 12px;
        outline: none;
        border: none;
        opacity: .9;

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            opacity: 1;
        }
        }
    }

    .memo {
        margin-top: 8px;

        &-icon {
        color: $darkBlue;
        margin-right: 10px;
        }

        span {
        white-space: break-spaces;
        word-break: break-word;
        }
    }
    }

    /* width */
    &::-webkit-scrollbar {
    width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
    background: $grey;
    border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
    background: $darkGrey;
    border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
    background: $darkGrey;
    }
  }
}

.summary {
  background-color: $grey;
  padding: 10px 16px;
  

  &_item {
      display: flex;
      justify-content: space-between;
      color: $darkGrey;
      font-family: "Inter-SemiBold";
      font-size: 1em;
      margin-bottom: 10px;
  }

  &_total {
    display: flex;
    justify-content: space-between;
    color: $black;
    font-family: "Inter-SemiBold";
    font-size: 1.2em;
    padding-top: 10px;
    border-top: 3px dashed $darkGrey;
  }
}

.empty {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &_title {
    font-family: "Inter-SemiBold";
    font-size: 1.2em;
    margin-top: 10px;
  }

  &_subtitle {
    font-family: "Inter-Regular";
    font-size: 1em;
    color: $darkGrey;
    padding: 5px 20px;
  }

  img {
    width: 100%;
  }
}